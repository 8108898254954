import { Typography, Box, Card, Grid} from '@mui/material'
import { Link } from 'react-router-dom';
import { useContext } from 'react'

import { UserContext } from '../contexts/UserContext'
import { MobileContext } from '../contexts/MobileContext';


// verb: whatever you want the user to do
//  ex: nominating, voting, rating
export default function StatusCards({verb}) {
    const { user } = useContext(UserContext)
    const { mobile } = useContext(MobileContext)

    const validUser = user.email && user.registration_data.registered && (user.registration_data.paid || !user.registration_data.required)

    return (
        <Box sx={{marginTop: 'auto', marginBottom: 'auto', width: '100%'}}>
            { !user.email && // USER NOT SIGNED IN
                <Grid justifyContent='center' alignItems='center' display='flex'>
                    <Box width={mobile ? '75%' : '50%'}>
                        <Card sx={{paddingX: '50px', paddingTop: '30px', paddingBottom: '10px', borderRadius: '20px', backgroundColor: '#fffef2', maxHeight: '600px', margin: '0px'}}>  
                        <div className='center'>
                            <img src='images/little-guys/cassette.png' width='100%'></img>
                        </div>
                        <Typography variant='h6' textAlign='left' fontWeight='bold' paddingTop='20px'>please sign in before {verb}</Typography>
                        <Box textAlign='right' marginRight='-20px'>
                            <img src='images/logos/tamu-aotw-logo.png' width='50px' ></img>
                        </Box>
                        </Card>
                    </Box>
                </Grid>
            }

            { user.email && !user.registration_data.registered && // USER NOT REGISTERED
                <Grid justifyContent='center' alignItems='center' display='flex'>
                    <Box width='50%'>
                        <Card sx={{paddingX: '50px', paddingTop: '30px', paddingBottom: '10px', borderRadius: '20px', backgroundColor: '#fffef2', maxHeight: '600px', margin: '0px'}}>
                        <div className='center'>
                            <img src='images/little-guys/cassette.png' width='100%'></img>
                        </div>
                        <Typography variant='h6' textAlign='left' fontWeight='bold' paddingTop='20px'>please <a href='/register'>register</a> before {verb}</Typography>
                        <Box textAlign='right' marginRight='-20px'>
                            <img src='images/logos/tamu-aotw-logo.png' width='50px' ></img>
                        </Box>
                        </Card>
                    </Box>
                </Grid>
            }

            { user.email && user.registration_data.registered && !validUser && // USER NOT PAID DUES
                <Grid justifyContent='center' alignItems='center' display='flex'>
                    <Box width='50%'>
                        <Card sx={{paddingX: '50px', paddingTop: '30px', paddingBottom: '10px', borderRadius: '20px', backgroundColor: '#fffef2', maxHeight: '600px', margin: '0px'}}>
                        <div className='center'>
                            <img src='images/little-guys/cassette.png' width='100%'></img>
                        </div>
                        <Typography variant='h6' textAlign='left' fontWeight='bold' paddingTop='20px'>please <Link href='https://tamu.estore.flywire.com/products?storeCatalog=8752'>pay dues</Link> before {verb}</Typography>
                        <Box textAlign='right' marginRight='-20px'>
                            <img src='images/logos/tamu-aotw-logo.png' width='50px' ></img>
                        </Box>
                        </Card>
                    </Box>
                </Grid>
            }
        </Box>
    )

}