import { useEffect, useState, useContext } from 'react'
import axios from 'axios'
import { Typography, Button, Container, Card, Checkbox, FormControlLabel, Link, TextField, Box } from '@mui/material'

import { UserContext } from '../contexts/UserContext'


function RegistrationPage ()
{
    document.title = 'Register | AOTW';

    const [ messages, setMessages ] = useState( {} )
    const { user } = useContext( UserContext )

    const [ agreed, setAgreed ] = useState( false )
    const [ registrationStatus, setRegistrationStatus ] = useState( { registered: false, paid: false } )

    const [ nickName, setName ] = useState()



    useEffect( () =>
    {
        if ( user.email ) checkRegistration()
    }, [ user ] )

    return (
        <div className="page tan-bg">
            <Container>
                <Card sx={ { padding: '10px', margin: '2%' } }>
                    <Typography variant='h6' align='center'>MISSION</Typography>
                    <Typography >Texas A&M Album of the Week (AOTW) is a community of passionate students who are encouraged to broaden their sense of music appreciation while sharing their personal music tastes and experiences.</Typography>
                    <Typography variant='h6' align='center'>RULES</Typography>
                    <Typography>Our mission is best supported by the following rules known as our “Core Characteristics”:</Typography>
                    <Container sx={ { padding: '10px' } }>
                        <Typography align='left'>1. Respect: Members must actively listen to other members and give them the opportunity to speak during discussions.</Typography>
                        <Typography align='left'>2. Inclusion: Harassment and discrimination of any kind is strictly prohibited.</Typography>
                        <Typography align='left'>3. Acceptance: Entitlement, gatekeeping, and ego-boosting are strictly prohibited.</Typography>
                        <Typography align='left'>4. Professionalism: Members must refrain from conducting inappropriate behavior both online and in person. Inappropriate online behavior includes spam, NSFW posts, harassment through direct messages, etc.</Typography>
                        <Typography align='left'>5. Honor: Integrity goes beyond academia. Students are expected to uphold the Aggie Honor Code in all club activities and interactions.</Typography>
                    </Container>
                    <Typography variant='h6' align='center'>Constitution</Typography>
                    <Typography><Link href="https://drive.google.com/file/d/19g57gYMQtPg_GXJ0fMxnJz3Ay6EBTG7L/view">Here</Link> is a link to the organization's constitution. It is not required nor expected for you to read this, but it is linked if you would like to read over it.</Typography>

                    { !registrationStatus.registered &&
                        <div className='center'>
                            <FormControlLabel control={ <Checkbox onChange={ ( event ) => setAgreed( event.target.checked ) } /> } label="I agree to uphold these Core Characteristics" />
                            <Box></Box>
                            <TextField size="small" onChange={ ( e ) => setName( e.target.value ) } label="Preferred First Name" ></TextField>
                            <Box paddingY='0.5%'></Box>
                            <Button variant='contained' marginTop='5%' onClick={ register }>register</Button>
                        </div> }

                    { registrationStatus.registered &&
                        <div className='center'>
                            <Typography variant='h6' align='center'>Thank you for registering!</Typography>
                            { registrationStatus.paid &&
                                <Typography variant='h6' align='center' color="green">Your dues have been paid!</Typography>
                            }

                            { !registrationStatus.paid &&
                                <div>

                                    <Typography variant='h6' color="red">Please click <Link href="https://tamu.estore.flywire.com/products?storeCatalog=8752">HERE</Link> to pay your dues. It may take a while for this to take effect.</Typography>
                                </div> }
                        </div>
                    }
                </Card>

                <Typography sx={ { color: 'red' } } align='center' variant='h2' id='display'>{ messages.error }</Typography>
                <Typography sx={ { color: 'green' } } align='center' variant='h2' id='display'>{ messages.success }</Typography>
            </Container>

        </div >
    )

    function register ()
    {
        if ( user === null || user.email === undefined || user.email.slice( -8 ) !== 'tamu.edu' )
        {
            setMessages( { error: "Please sign in with your TAMU email. If you do not have a TAMU email, please speak to an officer." } )
            return
        }
        if ( !agreed )
        {
            setMessages( { error: "Please agree to the rules before registering" } )
            return
        }

        const nickNameToSet = nickName.trim() === '' ? user.firstName : nickName.trim();

        let options = {
            method: 'POST',
            url: '/api/register-user',
            data: {
                email: user.email,
                fname: user.firstName,
                lname: user.lastName,
                nickName: nickNameToSet

            }
        }
        axios.request( options ).then( ( res ) =>
        {
            if ( res.data.error )
            {
                console.log( 'Error registering' )
                setMessages( { error: 'Error registering' } )
                return
            }
            setMessages( { success: `Welcome to the club ${ nickName }!` } )
        } ).catch( ( err ) =>
        {
            console.log( "error registering" )
            setMessages( { error: "There was an error with your registration, please try again." } )
        } )

        checkRegistration()
    }

    function checkRegistration ()
    {
        let options = {
            method: 'GET',
            url: '/api/check-registration-status',
            params: {
                email: user.email
            }
        }
        axios.request( options ).then( ( res ) =>
        {
            if ( res.data.error )
            {
                console.log( res.data.message )
                setMessages( res.data.message )
                return
            }
            setRegistrationStatus( res.data )
        } ).catch( ( err ) =>
        {
            setMessages( { error: "There was an error checking your registration status." } )
        } )
    }
}



export default RegistrationPage

